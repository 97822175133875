body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.speech-bubble,
.tenant-speech-bubble {
  position: relative;
  background: #eee;
  border-radius: 0.4em;
  padding: 10px;
  max-width: 65%;
  font-size: 14px;
  font-weight: 600;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 30px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-top: 0;
  margin-top: -14px;
  margin-left: -15px;
}

.tenant-speech-bubble:before {
  content: '';
  position: absolute;
  right: 0;
  top: 30px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #eee;
  border-right: 0;
  border-top: 0;
  margin-top: -14px;
  margin-right: -15px;
}
