.sidebar {
  z-index: 12;
  width: 220px;
  height: 100%;
}
.sidebar-inner {
  position: fixed;
  width: 220px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  background: #614285;
  padding: 10px;
  padding-right: 0px;
  color: #fff;
  z-index: 999;
}
.brand-style {
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
}

.link {
  display: flex;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 15px;
  padding: 0px 5px 0 5px;
  margin: 10px 0;
  font-size: 0.9em;
  align-items: center;
}

.close-side-bar {
  display: flex;
  justify-content: flex-start;
  margin: 15px;
}

.mobileStyle {
  position: fixed;
  bottom: 0;
  top: 0;
  box-shadow: 2px 2px 8px #a4a4a4;
}
