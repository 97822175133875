@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.speech-bubble,
.tenant-speech-bubble {
  position: relative;
  background: #eee;
  border-radius: 0.4em;
  padding: 10px;
  max-width: 65%;
  font-size: 14px;
  font-weight: 600;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 30px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-right-color: #eee;
  border-left: 0;
  border-top: 0;
  margin-top: -14px;
  margin-left: -15px;
}

.tenant-speech-bubble:before {
  content: '';
  position: absolute;
  right: 0;
  top: 30px;
  width: 0;
  height: 0;
  border: 15px solid transparent;
  border-left-color: #eee;
  border-right: 0;
  border-top: 0;
  margin-top: -14px;
  margin-right: -15px;
}

html,
body {
  min-width: 100vw;
  min-height: 100vh;
}
.App {
  font-family: 'Montserrat', sans-serif;
  background-color: #f2f2f2;
  min-height: 100vh;
  overflow: hidden;
}
input::placeholder {
  color: rgb(5, 5, 5);
}
.active {
  background: #f2f2f2;
  color: black;
  font-weight: 700;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  position: relative;
}
.active::before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background-color: #614285;
  right: 0;
  top: -30px;
  bottom: 100px;
  border-bottom-right-radius: 13px;
  box-shadow: 0px 17px 0px #f2f2f2;
}
.active:after {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background-color: #614285;
  right: 0;
  top: 100%;

  border-top-right-radius: 13px;
  box-shadow: 0px -17px 0px #f2f2f2;
}
.app-container {
  display: flex;
  width: 100%;
}
/* th,
td {
  font-size: 0.7em !important;
} */
/* 
th {
  color: #614285 !important;
} */
td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pagination-arrow {
  font-size: 0.9em !important;
}

@media screen and (max-width: 600px) {
  td {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 1em !important;
  }
  .pagination-arrow {
    font-size: 0.7em !important;
  }
}
/* stop chakra from applying shadow on checkbox */
.css-1c5vj91:focus {
  box-shadow: none !important;
}

.css-1c5vj91[data-focus] {
  box-shadow: none !important;
}

.css-mwso4b:focus,
.css-mwso4b[data-focus] {
  box-shadow: none !important;
}

/* chakra checkbox color when clicked */
.css-1ofo05q[aria-checked='true'],
.css-1ofo05q[data-checked] {
  background: #3cdb4c;
  border: 1px solid #3cdb4c;
}

.truncate {
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertical {
  width: 1px;
  height: 90px;
  background-color: #545353;
}
.notice-arrow {
  font-weight: 700;
  font-size: 26px;
  width: 30px;
  color: red;
}

.content {
  /* max-height: calc(100vh - 65px */
  width: 100%;
}

.actives {
  display: block;
}

.inactive {
  display: none;
}

.success {
  animation-name: success;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes success {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.errorAnimation {
  animation-name: error;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

@keyframes error {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* ScrollBar Stylings*/
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f2f2f2;
}

input::-webkit-input-placeholder {
  color: #cccccc;
  position: relative;
  padding-left: 0;
  /* For Safari 3.1 to 6.0 */
  -webkit-transition: padding 0.5s;
  transition: padding 0.5s;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
  position: relative;
  padding-left: 0;
  /* For Safari 3.1 to 6.0 */
  -moz-transition: padding 0.5s;
  transition: padding 0.5s;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
  position: relative;
  padding-left: 0;
  /* For Safari 3.1 to 6.0 */
  -moz-transition: padding 0.5s;
  transition: padding 0.5s;
}

input:-ms-input-placeholder {
  color: #cccccc;
  position: relative;
  padding-left: 0;
  /* For Safari 3.1 to 6.0 */
  -ms-transition: padding 0.5s;
  transition: padding 0.5s;
}
.react-datetime-picker {
  width: 100%;
}

.react-datetime-picker__wrapper {
  border: 1px solid rgb(219, 219, 219) !important;
  border-radius: 4px;
  height: 34px;
  background: #f6f6f6;
  font-size: 14px;
  padding-left: 5px;
}

.navigation-inner {
  background: #fff;
  border-radius: 3px;
  color: #a08ca8;
  font-weight: bold;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.toggle-side-bar {
  align-self: center;
}

.sidebar {
  z-index: 12;
  width: 220px;
  height: 100%;
}
.sidebar-inner {
  position: fixed;
  width: 220px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  background: #614285;
  padding: 10px;
  padding-right: 0px;
  color: #fff;
  z-index: 999;
}
.brand-style {
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  margin-bottom: 5px;
}

.link {
  display: flex;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 15px;
  padding: 0px 5px 0 5px;
  margin: 10px 0;
  font-size: 0.9em;
  align-items: center;
}

.close-side-bar {
  display: flex;
  justify-content: flex-start;
  margin: 15px;
}

.mobileStyle {
  position: fixed;
  bottom: 0;
  top: 0;
  box-shadow: 2px 2px 8px #a4a4a4;
}

.street-card {
  background-color: #fff;
  display: grid;
  grid-template-rows: -webkit-max-content 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-column: max-content 1fr;
}

